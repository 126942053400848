import Head from 'next/head'

const Preload = props => {
  const { as, href, ...rest } = props
  return (
    <Head>
      <link rel="preload" as={as} href={href} key={`preload-${href}`} {...rest} />
    </Head>
  )
}

export default Preload
