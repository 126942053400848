import React, { useMemo } from 'react'
import { EMPTY_IMAGE } from 'services/constants'
import { cloudinaryImage } from 'services/util/cloudinary'
import styles from './img.scss'
import Preload from 'components/preload'
import { noop } from 'services/util/abstract'
import cn from 'classnames'

if (typeof window !== 'undefined') {
  const lazySizes = require('lazysizes')
  require('lazysizes/plugins/attrchange/ls.attrchange')
  require('lazysizes/plugins/bgset/ls.bgset')
  require('lazysizes/plugins/parent-fit/ls.parent-fit')

  const config = lazySizes.cfg
  config.loadMode = 1 // load only elements in the viewport
  config.loadHidden = false
  config.preloadAfterLoad = true // load everything else when DOM is loaded
  config.ricTimeout = 500 // request idle callback
  config.throttleDelay = 150
  lazySizes.init()
}

// React.Fragment that does not throw error when props contain anything else than key and children
const NoWrapper = ({ children }) => <>{children}</>

// Props we want to pass from our <Img> component to the cloudinary url builder
const CLOUDINARY_IMAGE_OPTIONS = [
  'format',
  'quality',
  'width',
  'height',
  'gravity',
  'crop',
  'zoom',
  'dpr',
  'hasWatermark',
  'serveOriginal',
]

const Img = props => {
  const {
    src,
    lazy = true,
    publicId,
    wrapper,
    className = '',
    blurred,
    onLoad = noop,
    ...rest
  } = props
  const { preload = !lazy } = props // do not preload lazy-loaded images
  const Wrapper = wrapper || NoWrapper

  const [url, imgProps] = useMemo(() => {
    const imgProps = { ...rest }
    const cloudinaryImageOptions = {}
    for (const key of CLOUDINARY_IMAGE_OPTIONS) {
      cloudinaryImageOptions[key] = imgProps[key]
      delete imgProps[key]
    }

    const url = publicId ? cloudinaryImage(publicId, cloudinaryImageOptions) : src

    return [url, imgProps]
  }, [publicId, src, ...Object.values(rest)])

  // using two React.Fragments to prevent <style jsx> from adding className to <Preload>
  return (
    <>
      {Boolean(preload) && <Preload as="image" href={url} />}
      <Wrapper className={className}>
        <img
          src={lazy ? EMPTY_IMAGE : url}
          data-src={lazy ? url : null}
          className={cn({ lazyload: lazy, [className]: !wrapper, blurred: blurred })}
          onLoad={onLoad}
          // onError={() => Track.imageLoadFailure(`Image failed to load: ${url}`)}
          {...imgProps}
        />
      </Wrapper>
      <style jsx>{styles}</style>
    </>
  )
}

export default Img
